// 账户管理
import  request  from "@/utils/request";

// 获取管理机构列表
export function getOrgList (parameter){
    return request({
       url:'/api/org/list',
       method:'post',
       data:parameter
   })
}

// 新增机构信息
export function newOrg (parameter){
    return request({
       url:'/api/org/insert',
       method:'post',
       data:parameter
   })
}

// 查看机构信息
export function viewOrg (parameter){
    return request({
       url:'/api/org/view',
       method:'post',
       data:parameter
   })
}

// 更新机构信息
export function updateOrg (parameter){
    return request({
       url:'/api/org/update',
       method:'post',
       data:parameter
   })
}

// AI在线检测二维码
export function viewOrgCode1 (parameter){
    return request({
       responseType: 'blob',
       url:'/api/org/viewOrgQrCode',
       method:'post',
       data:parameter
   })
}

// 社区筛查检测二维码
export function viewOrgCode2 (parameter){
    return request({
       responseType: 'blob',
       url:'/api/org/viewUserQrCode',
       method:'post',
       data:parameter
   })
}

// 检测任务详情--团提报告--脊柱数据
export function viewSpineOrgReport (parameter){
    return request({
       url:'/api/examine-task-detail/viewSpineOrgReport',
       method:'post',
       data:parameter
   })
}

// 检测任务详情--团提报告--足部数据
export function viewFootOrgReport (parameter){
    return request({
       url:'/api/examine-task-detail/viewFootOrgReport',
       method:'post',
       data:parameter
   })
}

export function genOrgRefreshToken (parameter){
    return request({
       url:'/api/org/genOrgRefreshToken',
       method:'post',
       data:parameter
   })
}