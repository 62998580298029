<template>
<div class="table">
  <el-table
    ref="multipleTable"
    :data="tableData"
    :highlight-current-row="true"
    tooltip-effect="dark"
    style="width:100%;color:#858585;"
    @selection-change="selectRow"
    :header-cell-style="tableHeaderCellStyle">
    <el-table-column
     type="selection"
     fill="#00CCCC"
     fixed="left">
    </el-table-column>
    <!-- 表头开始 -->
    <el-table-column
      label="账户号/手机号"
      width="170"
      prop="mobile"
      align="center">
    </el-table-column>
    <el-table-column
      label="用户名"
      prop="username"
      show-overflow-tooltip
      align="center">
    </el-table-column>
    <el-table-column
      label="昵称"
      prop="nickName"
      show-overflow-tooltip
      align="center">
    </el-table-column>
    <el-table-column
      prop="orgName"
      label="机构名称"
      align="center">
    </el-table-column>
    <el-table-column
      prop="orgType"
      label="机构类型"
      align="center">
    </el-table-column>
    
    <el-table-column
      prop="createTime"
      align="center"
      label="创建时间"
      show-overflow-tooltip>
    </el-table-column>
    <el-table-column
      prop="enabled"
      align="center"
      label="状态"
      >
    </el-table-column>
    <el-table-column
      align="center"
      label="操作"
      width="120">
      <template slot-scope="scope">
        <span style="color:#00cccc;cursor: pointer;" @click="clickRow(scope.row)">查看详情</span>
      </template>
    </el-table-column>
  </el-table>
  <!-- 分页 -->
  <div class="page">
  <!-- left -->
  <span class="pageLeft">共 {{count}} 条记录 第 {{currentPage}} / {{tatalPage}}页</span>
  <!-- right -->
  <el-pagination
      @size-change="SizeChange"
      background
      @current-change="CurrentChange"
      @prev-click="prevClick"
      @next-click="nextClick"
      :current-page="currentPage"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="onlyPage"
      :pager-count="5"
      layout="sizes, prev, pager, next, jumper"
      :total="count">
    </el-pagination>
  </div>
</div>
</template>

<script>
import {getWxList} from '@/api/accountController.js'
  export default {
    data() {
      return {
        tableData: [],
        multipleSelection:[],
        // 分页相关
        currentPage:1,// 当前页面,默认1
        tatalPage:0,//总页数,为加载时先为0，页面首次加载后去请求即可
        count:0,//总的条数记录
        onlyPage:10,// 当前页面可以展示多少条数据,默认10 
      }
    },
    mounted(){
      //初始化获取列表数据
      this.getData(1,10,null,null); 
    },
    //监听勾选值
    watch:{
        multipleSelection:{
            handler(pre){
              var tem = pre;
              if(tem.length==0){
                
                this.$parent.isDisable = true;
              }
              if(tem.length==1){
               
                this.$parent.isDisable = false;
                //console.log(tem);
                this.$parent.tableSelect = tem[0] ;
                if(tem[0].enabled == "启用"){
                  this.$parent.statusV = "禁用" ;
                }else{
                  this.$parent.statusV = "启用" ;
                }
               }
             if(tem.length>1){
                
                this.$parent.isDisable = true;
                 this.$message({
                  message: '当前仅支持下载单个数据！',
                  type: 'warning'
                });
              }
            },
            deep:true,
            immediate:true
        }
    },
    methods: {
      //表头样式回调
      tableHeaderCellStyle(){
          return "color:#fff;background-color:#00CCCC";
        },
      // getData
      getData(currentPage,onlyPage,accStatus,all){
              const rLoading = this.openLoading();// 开始loading
              getWxList({
              currentPage: currentPage,
              pageSize: onlyPage,
              enabled:accStatus,
              username:all
            }).then((res)=>{
              if(res.data.status == 200){
                this.tableData = res.data.data.records;
                this.tatalPage =parseInt(res.data.data.size);
                this.count =parseInt(res.data.data.total);
                rLoading.close() ;// 关闭loading
              }else{
                this.$message.error(res.data.message);
              }
            }).catch(res=>{
               this.$message.error(res);
            })
      },
      // 复选框选项
      selectRow(val) {
        //console.log(this.multipleSelection);
        this.multipleSelection = val;
      },

      // 分页相关
      SizeChange(val){
        this.onlyPage = val;
        this.getData(this.currentPage,this.onlyPage,this.$parent.accStatus,this.$parent.accAllSearch);//更新数据
      },
      CurrentChange(val){
        this.currentPage = val;
        //this.getData(this.currentPage,this.onlyPage);//更新数据
        this.getData(this.currentPage,this.onlyPage,this.$parent.accStatus,this.$parent.accAllSearch);
      },
      prevClick(val){
        this.currentPage = val ;
        //this.getData(this.currentPage,this.onlyPage);//更新数据
        this.getData(this.currentPage,this.onlyPage,this.$parent.accStatus,this.$parent.accAllSearch);

      },
      nextClick(val){
        this.currentPage = val ;
        //this.getData(this.currentPage,this.onlyPage);//更新数据
        this.getData(this.currentPage,this.onlyPage,this.$parent.accStatus,this.$parent.accAllSearch);

      },
      clickRow(e){
        this.$router.push({
                path:'/wxUserDetail',
                query:{
                   id:e.id,
                   type:e.type
                }
          })
      }
    }
  }
</script>
<style scoped>
.table{
    margin-top: .1563rem;
}
.el-button{
    color: #00A3A3 !important;
}
.action{
    display: flex;
    flex-direction: column;
}
.actionP{
  cursor: pointer;
  color: #00CCCC;
  margin: 0 !important;
}
.resoult{
  text-align: left;
}
.page{
  display: flex;
  margin-top: .3125rem;
  justify-content: space-between;
  align-items: center;
}
.pageLeft{
  color: #858585;
  font-size: .1094rem;
  line-height: .1875rem;
}
.el-pagination{
  color: #858585 !important;
  font-size: .1094rem !important;
  line-height: .1875rem !important;
}
</style>