import  request  from "@/utils/request";
//账号管理


// 账号管理列表--web
export function getWebList (parameter){
    return request({
       url:'/api/user/webUserList',
       method:'post',
       data:parameter,
      
   })
}

// 账号管理列表--wx
export function getWxList (parameter){
    return request({
       url:'/api/user/thirdUserList',
       method:'post',
       data:parameter
   })
}


//创建账户
export function createAccount (parameter){
    return request({
       url:'/api/user/register',
       method:'post',
       data:parameter
   })
}

//启用账户--------------------///
export function enableUser (parameter){
    return request({
       url:'/api/user/enableUser',
       method:'post',
       data:parameter
   })
}

//禁用账户
export function disableUser (parameter){
    return request({
       url:'/api/user/disableUser',
       method:'post',
       data:parameter
   })
}

//绑定机构 bindORG
export function bindORG (parameter){
    return request({
       url:'/api/user/bindOrg',
       method:'post',
       data:parameter
   })
}

//取消绑定机构 bindORG
export function cancelBindORG (parameter){
    return request({
       url:'/api/user/unBindOrg',
       method:'post',
       data:parameter
   })
}

//查询用户详情--web
export function viewUser (parameter){
    return request({
       url:'/api/user/viewWebUser',
       method:'post',
       data:parameter
   })
}


//查询用户详情--微信
export function viewWXUser (parameter){
    return request({
       url:'/api/user/viewWxUser',
       method:'post',
       data:parameter
   })
}

//更新用户信息
export function updateUser (parameter){
    return request({
       url:'/api/user/updateWebUser',
       method:'post',
       data:parameter
   })
}


//更新Web用户角色
export function updateUserRole (parameter){
    return request({
       url:'/api/user/updateUserRole',
       method:'post',
       data:parameter
   })
}